import React from "react";
import { Col, Container, Row } from "reactstrap";
import footerData from "./FOOTER_DATA";

function Footer() {
  return (
    <footer>
      <Container>
        <Row
          lg="4"
          md="2"
          sm="2"
          xs="1"
          className="footer-container">
          <Col className="footer-column">
            <div className="contactUs">
              <div className="footerTitle">
                <h4 className="icon-title">Contact</h4>
              </div>
              <div className="footerContent">
                <ul>
                  {footerData.Contact.map((item, key) => {
                    return (
                      <li key={key}>
                        <a
                          href={item?.menuLink}
                          target="_blank">
                          {item?.menuIcon}
                          {item?.menuName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="footerContent mt-3">
                <ul className="socialIcons">
                  {footerData.contactSocial.map((item, key) => {
                    return (
                      <li key={key}>
                        <a
                          href={item?.menuLink}
                          target="_blank"
                          className={item?.menuClass}>
                          {item?.menuIcon}
                          {item?.menuName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Col>
          <Col className="footer-column">
            <div className="socialMedia">
              <div className="footerTitle">
                <h4 className="icon-title">Help</h4>
              </div>
              <div className="footerContent">
                <ul>
                  {footerData.Help.map((item, key) => {
                    return (
                      <li
                        key={key}
                        className={item?.menuClass}>
                        <a
                          href={item?.menuLink}
                          target="_blank">
                          {item?.menuIcon}
                          {item?.menuName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Col>
          <Col className="footer-column">
            <div className="socialMedia">
              <div className="footerTitle">
                <h4 className="icon-title">Feature</h4>
              </div>
              <div className="footerContent">
                <ul>
                  {footerData.Feature.map((item, key) => {
                    return (
                      <li
                        key={key}
                        className={item?.menuClass}>
                        <a
                          href={item?.menuLink}
                          target="_blank">
                          {item?.menuIcon}
                          {item?.menuName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Col>
          <Col className="footer-column">
            <div className="socialMedia">
              <div className="footerTitle">
                <h4 className="icon-title">About</h4>
              </div>
              <div className="footerContent">
                <ul>
                  {footerData.About.map((item, key) => {
                    return (
                      <li
                        key={key}
                        className={item?.menuClass}>
                        <a href={item?.menuLink}>
                          {item?.menuIcon}
                          {item?.menuName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <div className="copyright-footer">
          <h5 className="productOfTitle">A Product of Teksaar Innovations</h5>
          <p className="copyright color-text-a">
            &copy; Copyright {new Date().getFullYear()}.{" "}
            <span className="">
              Teksaar Innovations Pvt. Ltd.
              <br /> All Rights Reserved.
            </span>
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
