import { TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import * as yup from "yup";
import EXCLUDE_EMAIL_LIST from "../../util/exclude_mail_list";
import IntlMessages from "../../util/IntlMessages";
import Spinner from "../shared/spinner/Spinner";

const Step1 = ({ company, setCompanyDetail, loading, setShowReg, plan }) => {
  const [isTermCheck, setIsTermCheck] = useState(false);

  let validationSchema = yup.object().shape({
    companyName: yup.string().trim().required("*Required"),
    companyEmail: yup.string().trim().required("*Required").email("Enter valid Email"),
  });
  const [isEmailInExcluded, setEmailInExcluded] = useState(false);

  const formik = useFormik({
    initialValues: {
      companyName: company ? company.companyName : "",
      companyEmail: company ? company.companyEmail : "",
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };
  const handleOnChange = () => {
    formik.setFieldValue("termsandcondition", !formik.values.termsandcondition);
  };

  const handleSubmit = async (values, resetForm) => {
    if (handleEmailChange(values.companyEmail)) {
      setCompanyDetail((prev) => ({ ...prev, ...values }));
    }
  };
  const handleEmailChange = (email) => {
    let domain = email.substring(email.indexOf("@") + 1);
    if (process.env?.REACT_APP_DEPLOY_ENV == "dev") {
      setEmailInExcluded(false);
      return true;
    } else {
      if (EXCLUDE_EMAIL_LIST.includes(domain)) {
        toast.error("Please provide valid email domain");
        setEmailInExcluded(true);
        return false;
      } else {
        setEmailInExcluded(false);
        return true;
      }
    }
  };

  return (
    <div className="login-6 companyReg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-section">
              <div className="formTittle">
                <h4>
                  <IntlMessages id={`heading.company.registration`} />
                </h4>
                <h6 style={{ color: "#6c757d" }}>
                  1 month(30 days) of free trial for <span style={{ color: "#008080" }}>{plan?.planType}</span> plan
                </h6>
              </div>
              <div className="login-inner-form">
                <Form
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center">
                      <FormGroup>
                        <TextField
                          error={formik.touched["companyName"] && formik.errors["companyName"]}
                          label={<IntlMessages id={`form.company.name`} />}
                          id="companyName"
                          name="companyName"
                          value={formik.values.companyName}
                          onChange={formik.handleChange}
                        />
                        {renderErrorMessage("companyName")}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center">
                      <FormGroup>
                        <TextField
                          error={(formik.touched["companyEmail"] && formik.errors["companyEmail"]) || isEmailInExcluded}
                          label={<IntlMessages id={`form.company.email`} />}
                          id="companyEmail"
                          name="companyEmail"
                          value={formik.values.companyEmail}
                          onChange={formik.handleChange}
                        />
                        {renderErrorMessage("companyEmail")}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="terms-checkbox">
                      <FormGroup>
                        <div
                          className="tacbox"
                          style={{ display: "flex", textAlign: "justify" }}>
                          <Input
                            type="checkbox"
                            id="termsandcondition"
                            name="termsandcondition"
                            value={formik.values.termsandcondition}
                            checked={formik.values.termsandcondition}
                            onChange={handleOnChange}
                            onClick={() => setIsTermCheck((prev) => !prev)}
                          />
                          &nbsp;
                          <label
                            htmlFor="termsandcondition"
                            className="termsHeading">
                            &nbsp;I agree to the &nbsp;
                            <Link
                              to="/terms-of-service"
                              className="linkText"
                              target="_blank"
                              style={{ marginLeft: "-6px" }}>
                              Terms & Conditions
                            </Link>
                            &nbsp;and &nbsp;
                            <Link
                              to="/privacy-policy"
                              className="linkText"
                              target="_blank">
                              Privacy Policy
                            </Link>
                            .
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {loading && (
                      <Col>
                        <div className="text-center">
                          <Spinner />
                        </div>
                      </Col>
                    )}
                    <Col
                      xl={12}
                      md={12}
                      xs={12}
                      className="text-center next-finish-btn">
                      <button
                        className={loading || !isTermCheck ? "button-disable" : "loginButton"}
                        type="submit"
                        disabled={isTermCheck ? (loading ? true : false) : !isTermCheck}>
                        <span>Next</span>
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
