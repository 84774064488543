import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import First from "../../assets/images/first.png";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const [showScheduleDemo, setShowScheduleDemo] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const params = new URLSearchParams(useLocation().search);
  const loginStr = params.get("showLogin");

  useEffect(() => {
    if (loginStr) {
      setShowLogin(true);
    }
  }, [loginStr]);
  const navigate = useNavigate();
  const handleClick = () => {
    setShowScheduleDemo(true);
  };
  const navStyle = {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const liStyle = {
    color: "#1208aa",
    fontSize: "16px",
    border: "0.3em solid #1208aa",
    borderRadius: "0.5em",
    width: "12em",
    height: "3em",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontFamily: "sans-serif",
    letterSpacing: "0.1em",
    textAlign: "center",
    lineHeight: "2.5em",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    transition: "0.5s",
    margin: "1em",
  };
  const handleClickroute = () => {
    window.location.assign("/#pricingPlanSection");
  };
  return (
    <div
      className="container-fluid vh-100 d-flex align-items-center justify-content-center"
      id="homeSection"
      style={styles.container}>
      <div className="row w-100">
        <div
          className="col-md-6 d-flex flex-column justify-content-center align-items-center text-center p-4"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}>
          <h1 className="homeHeading">Human Resource Management System</h1>
          <p className="subHeading">Looking for a secure, scalable, and robust solution for your HR needs? HRMSHOST is here to streamline and revolutionize your HR processes.</p>
          <br />
          <button
            class="btn1"
            id="customBtn"
            onClick={handleClickroute}>
            Get Started
          </button>
        </div>

        <motion.div
          className="col-md-6 d-none d-md-flex justify-content-center align-items-center"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, ease: "easeOut" }}>
          <motion.img
            src={First}
            alt="HRMS Illustration"
            className="img-fluid rounded"
            style={styles.image}
            animate={{
              y: [0, -15, 0],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        </motion.div>
      </div>

      <FloatingShapes />
    </div>
  );
};

const FloatingShapes = () => {
  const floatingVariants = {
    hover: { scale: 1.2, rotate: 20 },
  };

  return (
    <>
      <motion.div
        style={{ ...styles.circle, ...styles.circle1 }}
        animate={{
          y: [0, -20, 0],
          x: [0, 10, 0],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        whileHover="hover"
        variants={floatingVariants}
      />
      <motion.div
        style={{ ...styles.circle, ...styles.circle2 }}
        animate={{
          y: [0, -30, 0],
          x: [0, -15, 0],
        }}
        transition={{
          duration: 6,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        whileHover="hover"
        variants={floatingVariants}
      />
      <motion.div
        style={{ ...styles.circle, ...styles.circle3 }}
        animate={{
          y: [0, -40, 0],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        whileHover="hover"
        variants={floatingVariants}
      />
    </>
  );
};

export default Home;

const styles = {
  container: {
    backgroundImage: `url(${require("../../assets/images/back1.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    color: "white",
    overflow: "hidden",
    position: "relative",
  },
  image: {
    width: "80%",
    height: "auto",
    borderRadius: "10px",
  },
  button: {
    textDecoration: "none",
    color: "white",
    fontSize: "1.2rem",
    backgroundColor: "#004d4d",
    padding: "12px 25px",
    borderRadius: "50px",
    display: "inline-block",
    transition: "all 0.3s ease",
    border: "2px solid white",
  },
  circle: {
    position: "absolute",
    borderRadius: "50%",
    background: "rgba(255, 255, 255, 0.2)",
  },
  circle1: {
    width: "100px",
    height: "100px",
    top: "10%",
    left: "15%",
  },
  circle2: {
    width: "150px",
    height: "150px",
    bottom: "20%",
    right: "20%",
  },
  circle3: {
    width: "200px",
    height: "200px",
    top: "30%",
    right: "10%",
  },
};

const globalStyles = `
  @keyframes gradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);
