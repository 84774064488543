import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { AxiosAdminConfig } from "../../config/AxiosAdminConfig";
import { getAccessToken } from "../auth/HandleCookies";
import PlanDetail from "./PlanDetail";

export const PricingPlan = () => {
  const [planData, setPlanData] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState({
    Quarterly: { Starter: {}, Basic: {}, Standard: {}, Premium: {} },
    Yearly: { Starter: {}, Basic: {}, Standard: {}, Premium: {} },
  });
  const [selectedPlanDuration, setSelectedPlanDuration] = useState("Quarterly");
  const [durationType, setDurationType] = useState("Quarterly");

  const getPlans = async () => {
    let url = `${process.env.REACT_APP_ERMS_ADMIN_API}public/plans`;
    let accessToken = getAccessToken();
    if (accessToken != null) {
      await AxiosAdminConfig.get(`/er/plans`)
        .then((response) => {
          setPlanData(response);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      await axios
        .get(`${process.env.REACT_APP_ERMS_ADMIN_API}public/plans`)
        .then((response) => {
          setPlanData(response.data.data);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    if (planData.length > 0) {
      let filterPlans = {
        Quarterly: {
          Starter: {},
          Basic: {},
          Standard: {},
          Premium: {},
        },
        Yearly: {
          Starter: {},
          Basic: {},
          Standard: {},
          Premium: {},
        },
      };
      for (let plan of planData) {
        if (!filterPlans[plan?.durationType]) {
          filterPlans[plan?.durationType] = {};
        }
        filterPlans[plan?.durationType][plan?.planType] = { plan };
      }

      setFilteredPlans(filterPlans);
    }
  }, [planData]);

  useEffect(() => {
    setSelectedPlanDuration(filteredPlans["Quarterly"]);
  }, [filteredPlans]);

  const handlePlanDurationChange = (selectedPlanDuration) => {
    setSelectedPlanDuration(filteredPlans[selectedPlanDuration]);
    setDurationType(selectedPlanDuration);
  };

  return (
    <div
      id="pricingPlanSection"
      className="container-fluid py-4 wow zoomIn section-scroll">
      <br />
      <div className="container">
        <Fade bottom>
          <div
            className="text-center mx-auto mb-3"
            style={{ maxWidth: "600px" }}>
            <h4 className="sectionTittle">Pricing Plans</h4>
            <h3 className="">OUR BEST SELLING PLANS</h3>
          </div>
        </Fade>
        <div className="pricing-container">
          <h4 className="section-title discount-text">Register Now and get 1 month (30-day) FREE TRIAL</h4>
          <h5 className="section-title discount-text ">*** Get Big Discount on Yearly Plan ***</h5>
          <div className="pricing-switcher">
            <p className="fieldset">
              <input
                type="radio"
                name="duration-1"
                value="Quarterly"
                id="quarterly-1"
                onChange={(e) => handlePlanDurationChange(e.target.value)}
              />
              <label htmlFor="quarterly-1">Quarterly</label>
              <input
                type="radio"
                name="duration-1"
                value="Yearly"
                id="yearly-1"
                onChange={(e) => handlePlanDurationChange(e.target.value)}
              />
              <label htmlFor="yearly-1">Yearly</label>
              <span className="switch"></span>
            </p>

            {/* <h5 className="discount-text banner-discount">Big discount on Yearly Plans</h5> */}
          </div>
          <PlanDetail
            plans={selectedPlanDuration}
            durationType={durationType}
          />
        </div>
        <div className="noteSection">
          <h4 className="noteHead">Note :</h4>
          <ul className="notelist">
            <li className="noteContent">Buy Now {"->"} This will add the selected plan to the end date of current plan.</li>
            <li className="noteContent">
              Upgrade Now {"->"} This will upgrade your current plan to the selected plan with immediate effect. <br />
              Current plan balance will be adjusted with the selected plan price.
            </li>
            <li className="noteContent">All the prices are Tax Exclusive.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
