import React, { useState } from "react";
import { AppBar, Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import {
  Menu as MenuIcon,
  Logout,
  AssignmentInd as AssignmentIndIcon,
  CurrencyRupeeOutlined as CurrencyRupeeOutlinedIcon,
  FeaturedPlayListOutlined as FeaturedPlayListOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
  HomeOutlined as HomeOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  LockReset as LockResetIcon,
  LoginOutlined as LoginOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import hrmsHostLogo250by60 from "../../assets/images/hrmshost-logo-250x60.png";
import ChangePassword from "../../components/auth/ChangePassword";
import { AxiosAdminConfig } from "../../config/AxiosAdminConfig";
import { logoutApi } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import { clearCookies, getAccessToken, getProfile } from "../auth/HandleCookies";
import SignIn from "../auth/Login";
import CompanyRegistrationWrapper from "../companyRegistration/CompanyRegistrationWrapper";

const styles = {
  appBar: {
    background: "#fff",
    position: "sticky",
    height: "72px",
    top: "0",
    zIndex: "999",
    justifyContent: "space-evenly",
  },
  navlinks: {
    textAlign: "center",
  },
  logoSection: {
    flex: "3",
    cursor: "pointer",
    color: "#6c757d",
  },
  loggedIn: {
    textAlign: "center",
    flex: "6",
  },
  loggedOut: {
    textAlign: "right",
  },
  link: {
    textDecoration: "none",
    color: "#6c757d",
    fontSize: "16px",
    marginLeft: "24px",
    textTransform: "none",
    letterSpacing: "1px",
    fontWeight: "500",
    "&:hover": {
      color: "#008080",
    },
    borderBottom: "3px solid transparent",
    paddingBottom: "4px",
  },
  activeLink: {
    borderBottom: "3px solid #008080",
  },
};

function DrawerComponent({ token }) {
  const [showLogin, setShowLogin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const onMenuSelect = (url) => {
    setAnchorEl(null);
    window.location.href = url;
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", top: "60px" }}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          className="myProfilePopup">
          <MenuItem onClick={() => onMenuSelect("/#homeSection")}>
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.home`} />
          </MenuItem>

          <MenuItem onClick={() => onMenuSelect("/#featuresSection")}>
            <ListItemIcon>
              <FeaturedPlayListOutlinedIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.feature`} />
          </MenuItem>
          <MenuItem onClick={() => onMenuSelect("/#pricingPlanSection")}>
            <ListItemIcon>
              <CurrencyRupeeOutlinedIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.pricing`} />
          </MenuItem>
          <MenuItem onClick={() => onMenuSelect("/#FAQ")}>
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <IntlMessages id={`navbar.text.faq`} />
          </MenuItem>

          {!token && (
            <>
              <MenuItem onClick={() => onMenuSelect("/#aboutWraper")}>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <IntlMessages id={`navbar.text.aboutUs`} />
              </MenuItem>
              <MenuItem onClick={() => setShowLogin(true)}>
                <ListItemIcon>
                  <LoginOutlinedIcon />
                </ListItemIcon>
                <IntlMessages id={`navbar.text.login`} />
              </MenuItem>
            </>
          )}
        </Menu>
      </Box>

      <IconButton onClick={openMenu}>
        <MenuIcon />
      </IconButton>
      {showLogin && <SignIn setShowLogin={setShowLogin} />}
    </>
  );
}

function Navbar({ activeSection }) {
  const navigate = useNavigate();
  const accessToken = getAccessToken();
  const profile = JSON.parse(getProfile());
  const theme = useTheme();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const isMobile = useMediaQuery("(max-width: 880px)");

  const [openDialog, setOpenDialog] = useState(false);
  const [openChangePassword, setChangePassword] = useState(false);

  const [showLogin, setShowLogin] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const handleLinkClick = (tab) => {
    setActiveTab(tab);
  };

  const logout = () => {
    clearCookies();
    window.location.href = "/";
  };
  const handleShow = () => {
    setOpenDialog(true);
  };

  const handlePortalRedirect = async () => {
    const profile = JSON.parse(getProfile());
    const userName = profile?.username;
    await AxiosAdminConfig.post(`${logoutApi}?username=${encodeURIComponent(userName)}`);
    window.open(process.env.REACT_APP_PORTAL_URL);
  };

  const [showRegForm, setShowRegForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onMyProfileClick = () => {
    setOpenDialog(true);
    handleClose();
  };

  const onChangePasswordClick = () => {
    setChangePassword(true);
    handleClose();
  };

  const onMyOrdersClick = () => navigate("/orders");

  return (
    <>
      <AppBar
        position="sticky"
        style={styles.appBar}>
        <div className="navWrapper">
          <div style={styles.logoSection}>
            <img
              src={hrmsHostLogo250by60}
              alt="Human-resource-Management-system"
              className="company-logo ms-2"
              height={isMobile ? "40px" : "50px"}
              onClick={() => navigate("/")}
            />
          </div>

          {!isMobile && (
            <div style={{ ...styles.navlinks, ...(accessToken ? styles.loggedIn : styles.loggedOut) }}>
              <a
                href="/#homeSection"
                style={{
                  ...styles.link,
                  ...(activeSection === "homeSection" ? styles.activeLink : {}), // **(Pink Highlight)**
                }}
                onClick={() => handleLinkClick("homeSection")}>
                <IntlMessages id={`navbar.text.home`} />
              </a>
              {!accessToken && (
                <a
                  href="/#aboutWraper"
                  style={{
                    ...styles.link,
                    ...(activeSection === "aboutWraper" ? styles.activeLink : {}), // **(Pink Highlight)**
                  }}
                  onClick={() => handleLinkClick("aboutWraper")}>
                  <IntlMessages id={`navbar.text.aboutUs`} />
                </a>
              )}
              <div
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setDropdownVisible(true)}
                onMouseLeave={() => setDropdownVisible(false)}>
                <a
                  href="/#featuresSection"
                  style={{
                    ...styles.link,
                    ...(activeSection === "featuresSection" ? styles.activeLink : {}),
                  }}
                  onClick={() => handleLinkClick("featuresSection")}>
                  <IntlMessages id={`navbar.text.features`} />
                </a>
                {dropdownVisible && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      backgroundColor: "#fff",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 10,
                      borderRadius: "4px",
                      padding: "10px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}>
                    {[
                      { href: "/employee-management", label: "Employee Management" },
                      { href: "/payroll-management", label: "Payroll Management" },
                      { href: "/leave-attendance-management", label: "Leave Management" },
                      { href: "/compensation-taxes", label: "Compensation & Taxes" },
                      { href: "/asset-project", label: "Asset & Project" },
                      { href: "/document-management", label: "Document Management" },
                    ].map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        style={{
                          padding: "10px 15px",
                          textDecoration: "none",
                          color: "#333",
                          borderRadius: "4px",
                          backgroundColor: "#f9f9f9",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          flex: "1 1 auto",
                          textAlign: "center",
                        }}>
                        {item.label}
                      </a>
                    ))}
                  </div>
                )}
              </div>
              <a
                href="/#pricingPlanSection"
                style={{
                  ...styles.link,
                  ...(activeSection === "pricingPlanSection" ? styles.activeLink : {}), // **(Pink Highlight)**
                }}
                onClick={() => handleLinkClick("pricingPlanSection")}>
                <IntlMessages id={`navbar.text.pricing`} />
              </a>
              <a
                href="/#FAQ"
                style={{
                  ...styles.link,
                  ...(activeSection === "FAQ" ? styles.activeLink : {}), // **(Pink Highlight)**
                }}
                onClick={() => handleLinkClick("FAQ")}>
                <IntlMessages id={`navbar.text.faq`} />
              </a>
              {!accessToken && (
                <Link
                  to="#"
                  style={{ ...styles.link }}
                  onClick={() => setShowLogin(true)}>
                  <IntlMessages id={`navbar.text.login`} />
                </Link>
              )}
            </div>
          )}

          {isMobile && <DrawerComponent token={accessToken} />}

          <div>
            {!accessToken && (
              <a
                href="/#pricingPlanSection"
                className="freeTrailHeaderButton">
                <span className="freeTrailHeaderButtonText">
                  <IntlMessages id={"navbar.text.start.free.trial"} />
                </span>
              </a>
            )}
          </div>

          {accessToken && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", top: "60px" }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}>
                    <Avatar
                      className="bgColor profileSize"
                      onClick={handleShow}>
                      {profile.firstName.substring(0, 1).toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                className="myProfilePopup">
                <MenuItem className="position">
                  <Avatar className="bgColor">{profile.firstName.substring(0, 1).toUpperCase()}</Avatar>
                </MenuItem>
                <div className="position">{profile.firstName + " " + profile.lastName}</div>
                <div className="position">{profile.email}</div>
                <Divider />
                <MenuItem onClick={onMyProfileClick}>
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.myProfile`} />
                </MenuItem>
                <MenuItem onClick={onMyOrdersClick}>
                  <ListItemIcon>
                    <ShoppingCartOutlinedIcon />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.myOrders`} />
                </MenuItem>
                <MenuItem onClick={onChangePasswordClick}>
                  <ListItemIcon>
                    <LockResetIcon />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.changePassword`} />
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <IntlMessages id={`navbar.text.logout`} />
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </AppBar>

      {showRegForm && <CompanyRegistrationWrapper setShowRegForm={setShowRegForm} />}

      {openChangePassword && (
        <ChangePassword
          setChangePassword={setChangePassword}
          openChangePassword={openChangePassword}
        />
      )}

      {showLogin && <SignIn setShowLogin={setShowLogin} />}
    </>
  );
}

export default Navbar;
