import React from "react";
import { Col, Row } from "reactstrap";

const YoutubeVideo = () => {
  return (
    <div className="video-container">
      <div className="header-text">
        <span>Quick Video Links</span>
      </div>
      <Row className="row-center">
        <Col className="video-box-container mt-4">
          <iframe
            className="video-box"
            src="https://www.youtube.com/embed/NNfcIBB5U34"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            border-radius="0"></iframe>
        </Col>
        <Col className="video-box-container mt-4">
          <iframe
            className="video-box"
            src="https://www.youtube.com/embed/D5VE_fcVhNQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </Col>
        <Col className="video-box-container mt-4">
          <iframe
            className=" video-box"
            src="https://www.youtube.com/embed/ra_BGeyLeC8"
            title="Attendance &amp; Holiday Management"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </Col>
      </Row>
      <Row>
        <a
          className="readMoreLink"
          href="https://www.youtube.com/@hrmshost/videos"
          target="_blank">
          More Videos ...
        </a>
      </Row>
    </div>
  );
};

export default YoutubeVideo;
